import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import styled from "styled-components";
import { Modal, Button } from "antd";
import MetamaskLog from "assets/images/metamask.png";
import { getNetwork } from "utils";

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}
type ConnectWalletProp = {
  account: string;
  setAccount: Function;
  isConnected: boolean;
  setIsConnected: (v: boolean) => void;
};
const ConnectWallet = ({
  account,
  isConnected,
  setAccount,
  setIsConnected,
}: ConnectWalletProp) => {
  // const [isConnected, setIsConnected] = useState(false);
  // const [account, setAccount] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { chainID, chainName, hex, rpcUrl, explorer } = getNetwork();

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const ethEnabled = async () => {
    if (window.ethereum) {
      window.web3 = new ethers.providers.Web3Provider(window.ethereum, "any");
      const networkId = await window.ethereum.request({
        method: "net_version",
      });
      setIsModalVisible(false);
      try {
        if (networkId !== chainID) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: hex,
                chainName: chainName,
                nativeCurrency: {
                  name: "Neon",
                  symbol: "NEON",
                  decimals: 18,
                },
                rpcUrls: [rpcUrl],
                blockExplorerUrls: [explorer],
              },
            ],
          });
          const getAccount = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (getAccount) {
            setIsConnected(true);
            setAccount(getAccount[0]);
          } else {
            setIsConnected(false);
          }
          return true;
        } else {
          const getAccount = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (getAccount) {
            setIsConnected(true);
            setAccount(getAccount[0]);
          } else {
            setIsConnected(false);
          }
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    } else {
      alert("Please install Metamask");
    }
  };
  return (
    <>
      <div
        className={!isConnected ? "error" : "success"}
        style={{ marginBottom: "20px" }}
      >
        {!isConnected ? (
          <ButtonConnect onClick={showModal}>
            <TextMain>Connect a Wallet</TextMain>
          </ButtonConnect>
        ) : (
          <Connected>
            <TextMain>Connected</TextMain> [
            <Link to={`/address/${account}`}>{account}</Link>]
          </Connected>
        )}
      </div>
      <ModalStyled
        title="Connect a Wallet"
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <ButtonStyled
          style={{ borderColor: "rgba(115,95,115,0.2)" }}
          onClick={ethEnabled}
        >
          <TextMain style={{ fontSize: "18px" }}>MetaMask</TextMain>
          <img width="24px" src={MetamaskLog} alt="MetaMask" />
        </ButtonStyled>
        {/* {!isMetamask && alert('Metamask not install!')} */}
        {/* <ButtonStyled>
                    <TextMain style={{ fontSize: "18px" }}>WalletConnect</TextMain>
                    <img width="24px" src={WalletConnect} alt="WalletConnect" />
                </ButtonStyled> */}
      </ModalStyled>
    </>
  );
};

export default ConnectWallet;

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.bg2};
  }
  .ant-modal-header {
    background-color: ${({ theme }) => theme.bg2};
    border-bottom: 1px solid ${({ theme }) => theme.borderOverlay1};
    .ant-modal-title {
      color: ${({ theme }) => theme.text};
      font-size: 18px;
    }
  }
  .anticon-close {
    color: ${({ theme }) => theme.textOverlay};
  }
`;

const TextMain = styled.span`
  color: ${({ theme }) => theme.text};
`;
const Connected = styled.div`
  position: relative;
  span {
    padding-left: 20px;
  }
  :before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: green;
    top: 30%;
    left: 6px;
  }
`;
const ButtonConnect = styled(Button)`
  margin-bottom: 20px;
  padding-left: 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg8};
  border: none;
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.bg8};
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #b32e33;
    top: 36%;
    left: 6px;
  }
`;
const ButtonStyled = styled(Button)`
  height: auto;
  display: flex;
  width: 100%;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.bg2};
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.bg2};
  }
`;
