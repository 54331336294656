import BigNumber from "bignumber.js";
import { formatDistance, format, addMinutes } from "date-fns";
import { ethers } from "ethers";
import { API_URL, API_TESTNET_URL, API_DEVNET_URL } from "constants/api";

export function getContract(ABI: any, contractAddress: string) {
  const { rpcUrl } = getNetwork();
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  return new ethers.Contract(contractAddress, ABI, provider);
}

export function addressCheckSum(address: string) {
  if (address) {
    return ethers.utils.getAddress(address);
  } else {
    return "";
  }
}

export function getNetwork() {
  const currentUrl = window.location.href;
  const domain = new URL(currentUrl).origin;
  if (domain.includes("testnet")) {
    return {
      network: "testnet",
      chainID: 245022940,
      chainName: "Neon Testnet",
      hex: "0xe9ac0dc",
      apiUrl: API_URL,
      rpcUrl: "https://devnet.neonevm.org",
      explorer: "https://devnet.neonscan.org",
    };
  } else if (domain.includes("devnet")) {
    return {
      network: "devnet",
      chainID: 245022926,
      chainName: "Neon Devnet",
      hex: "0xe9ac0ce",
      apiUrl: API_URL,
      rpcUrl: "https://devnet.neonevm.org",
      explorer: "https://devnet.neonscan.org",
    };
  } else {
    return {
      network: "mainnet",
      chainID: 245022934,
      chainName: "Neon Mainnet",
      hex: "0xe9ac0d6",
      apiUrl: API_URL,
      rpcUrl: "https://neon-proxy-mainnet.solana.p2p.org",
      explorer: "https://neonscan.org",
    };
  }
}

export const getSize = (isClient: boolean) => {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };
};

export function getCluster(network: string) {
  switch (true) {
    case network === "testnet":
      return {
        query: "cluster=testnet",
        api: API_TESTNET_URL,
      };
    case network === "devnet":
      return {
        query: "cluster=devnet",
        api: API_DEVNET_URL,
      };
    default:
      return {
        query: "",
        api: API_URL,
      };
  }
}

export function getClusterPath(pathname: any, newParams?: URLSearchParams) {
  const params = new URLSearchParams(window.location.search);
  const cluster = params.get("cluster");
  newParams = newParams || new URLSearchParams();
  if (cluster) newParams.set("cluster", cluster);

  return {
    pathname,
    search: newParams.toString(),
  };
}

export function getApiUrl() {
  const params = new URLSearchParams(window.location.search.substring(1));
  const networkSelected = params.get("cluster") || "mainnet";

  return getCluster(networkSelected).api;
}

export function toNeon(amount: string | number | undefined, decimals = 18) {
  if (!amount) return amount;

  return new BigNumber(amount).div(10 ** decimals).toNumber();
}

export function formatNumber(
  number: string | number | undefined | null,
  precision = 18
) {
  if (!number) return number;

  let formatted = new BigNumber(number).toFormat(precision);

  if (formatted.match(/\.[0]+$/g)) {
    formatted = formatted.replace(/\.[0]+$/g, "");
  }

  if (formatted.match(/\.\d+[0]+$/g)) {
    formatted = formatted.replace(/[0]+$/g, "");
  }

  return formatted;
}

export function formatUtc(
  timestamp: number,
  pattern = "MMMM dd, yyyy HH:mm:ss 'UTC'" //  a '+UTC'
) {
  if (!timestamp) return null;

  const dateLocal = new Date(timestamp * 1000);
  return format(addMinutes(dateLocal, dateLocal.getTimezoneOffset()), pattern);
}

export function timeFormatUtc(
  timestamp: number | undefined,
  pattern = "MMMM dd, yyyy HH:mm:ss 'UTC'" //  a '+UTC'
) {
  if (!timestamp) return timestamp;

  const dateLocal = new Date(timestamp * 1000);
  return format(addMinutes(dateLocal, dateLocal.getTimezoneOffset()), pattern);
}

export function timeFormatDistance(time: number) {
  if (!time) return;

  return formatDistance(time * 1000, new Date(), {
    addSuffix: true,
  });
}

export function truncate(
  text: string,
  substr?: string,
  num = 12,
  symbol = "..."
) {
  const newText = text.toString();

  if (!text) return "";
  if (newText.length <= 2 * num) return newText;
  if (substr && substr === "top") {
    return newText.slice(0, num) + symbol;
  } else {
    const firtPart = newText.slice(0, num);
    const lastPart = newText.slice(-num);

    return firtPart + symbol + lastPart;
  }
}

export function hexToDec(hexString: string) {
  return parseInt(hexString, 16);
}

export function getTimeDistance(duration: string, custom?: number) {
  const timestampByValue = {
    H: custom ? custom * 60 * 60 * 1000 : 60 * 60 * 1000,
    D: 24 * 60 * 60 * 1000,
    W: 7 * 24 * 60 * 60 * 1000,
    M: 30 * 24 * 60 * 60 * 1000,
  };
  let offset: number;

  switch (duration.slice(-1)) {
    case "H":
      offset = timestampByValue.H;
      break;
    case "W":
      offset = timestampByValue.W;
      break;
    case "M":
      offset = timestampByValue.M;
      break;
    default:
      offset = timestampByValue.D;
      break;
  }
  const now = new Date().getTime();
  const from = now - offset;

  return { from: Math.floor(from / 1000), to: Math.floor(now / 1000) };
}

export const dataFormater = (number: number) => {
  if (number >= 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (number >= 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (number >= 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number.toString();
  }
};

export const dateFormater = (timestamp: number) => {
  if (!timestamp) return null;

  return formatUtc(timestamp, "dd/MM");
};
